import companyOverviewImage from "../assets/images/about-company-overview.jpg"
const CompanyOverview = () => {
    return (
      <div className="py-16 bg-white">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row gap-12 max-w-6xl">
            {/* Image on the left */}
            <div className="w-full md:w-1/2">
              <div className="h-full rounded-lg overflow-hidden shadow-lg">
                <img 
                  src={companyOverviewImage} 
                  alt="Three Phase Power Engineering" 
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
  
            {/* Content on the right */}
            <div className="w-full md:w-1/2 flex flex-col justify-center">
              <h2 className="text-3xl font-bold text-gray-900 mb-8">
                Three Phase Power Engineering Services (TPPES)
              </h2>
              <p className="text-lg text-gray-600 leading-relaxed">
                Founded with a vision to transform power system solutions, Three Phase Power Engineering Services (TPPES) stands at the forefront of electrical engineering excellence. We specialize in comprehensive power system studies, Protection automation, and data analytics, serving industries where reliability and efficiency are paramount.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  export default CompanyOverview;