

import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { motion, AnimatePresence } from 'framer-motion';
import CompanyLogo from './CompanyLogo';
import data from '../data.json';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const { menuItems } = data;

  const menuVariants = {
    closed: {
      height: 0,
      opacity: 0,
      transition: {
        duration: 0.3,
        when: 'afterChildren',
      },
    },
    open: {
      height: 'auto',
      opacity: 1,
      transition: {
        duration: 0.3,
        when: 'beforeChildren',
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    closed: { opacity: 0, y: -10 },
    open: { opacity: 1, y: 0 },
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    window.dispatchEvent(
      new CustomEvent('mobileMenuChange', { detail: !isOpen })
    );
  };

  return (
    <nav className="left-0 right-0 top-0 z-50 bg-white shadow-lg">
      <div className="max-w-7xl mx-auto p-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-12">
          <div className="flex items-center">
            <RouterLink to="/" className="text-white font-bold text-xl cursor-pointer">
              <CompanyLogo />
            </RouterLink>
          </div>
          {/* Navbar Menu */}
          <div className="hidden lg:flex items-center space-x-4">
            {menuItems.map((item) => (
              <RouterLink
                key={item.id}
                // to={`/${item.to}`}
                to={item.to === "/" ? item.to : `/${item.to}`}
                className={`text-grey-900 hover:text-[#780606] px-3 py-2 transition-all duration-300 cursor-pointer ${
                  hoveredItem && hoveredItem !== item.id ? 'opacity-50' : ''
                } ${
                  hoveredItem === item.id
                    ? 'font-bold border-b-2 border-[#780606]'
                    : ''
                }`}
                onMouseEnter={() => setHoveredItem(item.id)}
                onMouseLeave={() => setHoveredItem(null)}
              >
                {item.name}
              </RouterLink>
            ))}
            {/* Contact Us Button */}
            <RouterLink
              to="/contact"
              className="bg-custom-blue hover:bg-[#780606] text-white py-2 px-4 rounded transition duration-300 cursor-pointer"
            >
              Contact Us
            </RouterLink>
          </div>
          {/* Mobile Menu Button */}
          <div className="lg:hidden flex items-center">
            <button
              onClick={toggleMenu}
              className="inline-flex items-center justify-center p-2 rounded-md text-[#0A2A71] focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                {isOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                )}
              </svg>
            </button>
          </div>
        </div>
      </div>
      {/* Mobile Menu */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="lg:hidden bg-[#3d3c3b] p-4 overflow-hidden"
            variants={menuVariants}
            initial="closed"
            animate="open"
            exit="closed"
          >
            <motion.div className="px-2 pt-2 pb-3 space-y-1">
              {menuItems.map((item) => (
                <motion.div key={item.id} variants={itemVariants}>
                  <RouterLink
                    // to={`/${item.to}`}
                    to={item.to === "/" ? item.to : `/${item.to}`}
                    className="text-white hover:bg-white hover:text-black block px-3 py-2 text-sm font-medium cursor-pointer uppercase"
                    onClick={toggleMenu}
                  >
                    {item.name}
                  </RouterLink>
                </motion.div>
              ))}
              <motion.div variants={itemVariants}>
                <RouterLink
                  to="/contact"
                  className="text-white hover:bg-[#780606] block px-3 py-2 text-sm font-medium cursor-pointer uppercase"
                  onClick={() => setIsOpen(false)}
                >
                  Contact Us
                </RouterLink>
              </motion.div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default NavBar;