import React from "react";
import { motion } from "framer-motion";
import NavBar from "../components/NavBar";
import FooterSection from "../components/FooterSection";
import HeroSection from "../components/HeroSection";
import data from "../data.json";
import ourServicesImage from "../assets/images/our-services.jpg";

const TechnologyCard = ({ technology }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-all duration-300 border border-gray-100"
  >
    <div className="flex items-center mb-4">
      <div className="bg-blue-50 p-3 rounded-full">
        {technology.icon.endsWith('.svg') ? (
          <img 
            src={require(`../assets/svgs/${technology.icon}`)} 
            alt={technology.name} 
            className="w-8 h-8"
          />
        ) : (
          <div className="w-8 h-8 flex items-center justify-center text-custom-blue">
            {technology.name.charAt(0)}
          </div>
        )}
      </div>
      <h3 className="text-lg font-semibold text-gray-900 ml-4">
        {technology.name}
      </h3>
    </div>
    <p className="text-gray-600">{technology.description}</p>
  </motion.div>
);

const CategorySection = ({ category }) => (
  <div className="mb-16">
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="mb-8"
    >
      <h2 className="text-2xl font-bold text-gray-900 mb-3">{category.name}</h2>
      <p className="text-gray-600">{category.description}</p>
    </motion.div>

    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {category.technologies.map((tech, index) => (
        <TechnologyCard key={index} technology={tech} />
      ))}
    </div>
  </div>
);

const Technologies = () => {
  const { technologiesData } = data;

  return (
    <div className="min-h-screen flex flex-col">
      <NavBar />

      <HeroSection
        backgroundImage={ourServicesImage}
        heading="Our Technologies"
        description="Discover the cutting-edge technologies and tools we use to deliver exceptional results."
      />

      <div className="py-16 bg-gray-50">
        <div className="container mx-auto px-4 max-w-7xl">
          {/* Introduction */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Technology Stack
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              We leverage industry-leading technologies to ensure the highest
              standards of reliability, efficiency, and performance in power
              system solutions.
            </p>
          </motion.div>

          {/* Technology Categories */}
          {technologiesData.categories.map((category) => (
            <CategorySection key={category.id} category={category} />
          ))}
        </div>
      </div>

      <FooterSection />
    </div>
  );
};

export default Technologies;
