import React from 'react';
import { motion } from 'framer-motion';

const ExperienceSection = () => {
  const stats = [
    {
      number: "40+",
      label: "Years Combined Experience",
      description: "Decades of collective expertise in power systems"
    },
    {
      number: "100+",
      label: "Projects Completed",
      description: "Successful implementations across industries"
    },
    {
      number: "24/7",
      label: "Support Available",
      description: "Round-the-clock technical assistance"
    },
    {
      number: "100%",
      label: "Client Satisfaction",
      description: "Consistently exceeding expectations"
    }
  ];

  const expertise = [
    "Protective Relay Programming for SEL, GE, SIMENS, BECKWITH",
    "Power System Studies using ETAP",
    "Power System Automation & SCADA",
    "Loadshedding and Generator Control System",
    "Protective Relay Testing",
    "Project Comissioning & startup support",
    "Design Engineering",
    "Consulting services",
  ];

  return (
    <div className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <div className="max-w-6xl mx-auto">
          {/* Section Header */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center mb-12"
          >
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Our Experience & Expertise
            </h2>
            <p className="text-lg text-gray-600">
              Delivering excellence through decades of experience and comprehensive technical expertise
            </p>
          </motion.div>

          {/* Statistics Grid */}
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-16">
            {stats.map((stat, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                className="bg-white p-6 rounded-lg shadow-lg text-center"
              >
                <div className="text-4xl font-bold text-custom-blue mb-2">
                  {stat.number}
                </div>
                <div className="text-lg font-semibold text-gray-800 mb-2">
                  {stat.label}
                </div>
                <div className="text-gray-600 text-sm">
                  {stat.description}
                </div>
              </motion.div>
            ))}
          </div>

          {/* Expertise Areas */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="bg-gray-50 p-8 rounded-lg shadow-lg"
          >
            <h3 className="text-2xl font-bold text-gray-900 mb-6 text-center">
              Areas of Expertise
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              {expertise.map((item, index) => (
                <div 
                  key={index}
                  className="flex items-center bg-white p-4 rounded-lg shadow"
                >
                  <span className="text-custom-blue mr-2">✓</span>
                  <span className="text-gray-700">{item}</span>
                </div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default ExperienceSection;