import React from "react";
import { motion } from "framer-motion";

const WhoWeAreSection = () => {
  const coreServices = [
    "Power System Studies & Analysis",
    "Power System Protection & Coordination",
    "Power Monitoring System",
    "Automation & Control Systems",
    "Data Analytics & Monitoring",
    "Engineering Consultation",
    "Power Quality Solutions",
  ];

  const companyValues = [
    "Excellence in Engineering",
    "Customer-Centric Approach",
    "Innovation & Adaptability",
    "Safety & Reliability",
    "Sustainable Solutions",
  ];

  return (
    <div className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row gap-12 max-w-6xl">
          {/* Left Column - Who We Are */}
          <div className="w-full md:w-1/2">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
              className="bg-white p-8 rounded-lg shadow-lg"
            >
              <h2 className="text-3xl font-bold text-gray-900 mb-6">
                Who We Are
              </h2>
              <p className="text-gray-600 mb-6 leading-relaxed">
                We are a team of dedicated Power System engineering professionals
                committed to delivering innovative power system solutions. Our
                identity is built on technical excellence, reliability, and
                customer satisfaction.
              </p>
              <h3 className="text-xl font-semibold text-gray-800 mb-4">
                Our Values
              </h3>
              <ul className="space-y-3">
                {companyValues.map((value, index) => (
                  <li key={index} className="flex items-start">
                    <span className="text-custom-blue mr-2">✓</span>
                    <span className="text-gray-700">{value}</span>
                  </li>
                ))}
              </ul>
            </motion.div>
          </div>

          {/* Right Column - What We Do */}
          <div className="w-full md:w-1/2">
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
              className="bg-white p-8 rounded-lg shadow-lg"
            >
              <h2 className="text-3xl font-bold text-gray-900 mb-6">
                What We Do
              </h2>
              <p className="text-gray-600 mb-6 leading-relaxed">
                We provide comprehensive electrical power system solutions that
                enhance efficiency, reliability, and safety for our clients'
                infrastructure.
              </p>
              <h3 className="text-xl font-semibold text-gray-800 mb-4">
                Core Services
              </h3>
              <ul className="space-y-3">
                {coreServices.map((service, index) => (
                  <li key={index} className="flex items-start">
                    <span className="text-custom-blue mr-2">✓</span>
                    <span className="text-gray-700">{service}</span>
                  </li>
                ))}
              </ul>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoWeAreSection;
