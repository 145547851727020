import React from "react";
import { Link as RouterLink } from "react-router-dom";
import CompanyLogo from "./CompanyLogo";
import data from "../data.json";

const FooterSection = () => {
  const { menuItems } = data;
  return (
    // <div className="bg-gray-800 text-white py-5 px-5 pb-2 ">
    <div className="container mx-auto bg-white text-gray-900 p-5 pb-2 ">
      {" "}
      {/* First Subsection: 3 Columns */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-10 mb-6 py-5">
        {" "}
        {/* Column 1: Company Info */}
        <div>
          <RouterLink to="/" className="flex items-center">
            <CompanyLogo />
          </RouterLink>
          <p className="mt-4">
            Three Phase Power Engineering Services is a specialized provider of
            comprehensive electrical power system solutions, designed to
            optimize the efficiency, reliability, and safety of your power
            infrastructure. We leverage advanced technology and industry
            expertise to offer a wide range of services, tailored to meet the
            unique needs of our clients
          </p>
        </div>
        {/* Column 2: Useful Links */}
        <div>
          <h2 className="text-xl mb-4">Useful Links</h2>
          <ul className="space-y-2">
            {menuItems.map((item) => {
              // Determine the correct path
              let path = "/";
              if (item.name.toLowerCase() === "home") {
                path = "/";
              } else {
                path = `/${item.to}`;
              }

              return (
                <li key={item.id}>
                  <RouterLink
                    to={path}
                    className="text-grey-900 hover:text-[#780606] hover:underline px-3 py-2 transition-colors duration-300 cursor-pointer"
                  >
                    {item.name}
                  </RouterLink>
                </li>
              );
            })}
          </ul>
        </div>
        {/* Column 3: Contact Info */}
        <div>
          <h2 className="text-xl mb-4">Contact Info</h2>
          <div className="flex items-center mb-4">
            <span className="mr-2">
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
              >
                <path
                  d="M12 21C15.5 17.4 19 14.1764 19 10.2C19 6.22355 15.866 3 12 3C8.13401 3 5 6.22355 5 10.2C5 14.1764 8.5 17.4 12 21Z"
                  stroke="#111827" // Changed to gray-900
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
                  stroke="#111827" // Changed to gray-900
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>

            <a
              href="https://www.google.com/maps/place/8990+Kirby+Dr,+Houston,+TX+77054,+USA"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-custom-red transition-colors duration-300"
            >
              8990 Kirby Dr, Unit 220, South Main, Houston, United States, 77054
            </a>
          </div>
          <div className="flex items-center mb-4">
            <span className="mr-2">
              <svg
                height="24px"
                width="24px"
                version="1.1"
                id="_x32_"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="w-6 h-6"
              >
                <path
                  fill="#111827" // Changed to gray-900
                  d="M94.811,21.696c-35.18,22.816-42.091,94.135-28.809,152.262c10.344,45.266,32.336,105.987,69.42,163.165 
      c34.886,53.79,83.557,102.022,120.669,129.928c47.657,35.832,115.594,58.608,150.774,35.792 
      c17.789-11.537,44.218-43.058,45.424-48.714c0,0-15.498-23.896-18.899-29.14l-51.972-80.135 
      c-3.862-5.955-28.082-0.512-40.386,6.457c-16.597,9.404-31.882,34.636-31.882,34.636
      c-11.38,6.575-20.912,0.024-40.828-9.142c-24.477-11.262-51.997-46.254-73.9-77.947
      c-20.005-32.923-40.732-72.322-41.032-99.264c-0.247-21.922-2.341-33.296,8.304-41.006 
      c0,0,29.272-3.666,44.627-14.984c11.381-8.392,26.228-28.286,22.366-34.242l-51.972-80.134
      c-3.401-5.244-18.899-29.14-18.899-29.14C152.159-1.117,112.6,10.159,94.811,21.696z"
                />
              </svg>
            </span>

            <a
              href="tel:+17134437247"
              className="hover:text-custom-red transition-colors duration-300"
            >
              +1 713-443-7247
            </a>
          </div>
          <div className="flex items-center">
            <span className="mr-2">
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.75 5.25L3 6V18L3.75 18.75H20.25L21 18V6L20.25 5.25H3.75ZM4.5 7.6955V17.25H19.5V7.69525L11.9999 14.5136L4.5 7.6955ZM18.3099 6.75H5.68986L11.9999 12.4864L18.3099 6.75Z"
                  fill="#111827"
                />
              </svg>
            </span>

            <a
              href="mailto:ashaik@threephasepowers.com"
              className="hover:text-custom-red transition-colors duration-300"
            >
              ashaik@threephasepowers.com
            </a>
          </div>
        </div>
      </div>
      {/* Second Subsection: Rights and Credits */}
      <div className="flex justify-between items-end border-t border-gray-700 pt-1">
        <p className="text-xs">
          © 2025 Three Phase Powers LLC. All rights reserved.
        </p>
        <p className="text-xs">
          Website by{" "}
          <a
            href="https://www.linkedin.com/in/sanaan7788/"
            target="_blank"
            className="underline hover:text-[#780606] text-xs"
            rel="noreferrer"
          >
            Sanaan
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default FooterSection;
