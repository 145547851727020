import React from "react";
import NavBar from "../components/NavBar";
import FooterSection from "../components/FooterSection";
import HeroSection from "../components/HeroSection";
import CompanyOverview from "../components/CompanyOverview";
import WhoWeAreSection from "../components/WhoWeAreSection";
import ourServicesImage from "../assets/images/our-services.jpg";
import VisionMissionSection from "../components/VissionMissionSection";
import ExperienceSection from "../components/ExperienceSection";

const About = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <NavBar />
      <HeroSection
        backgroundImage={ourServicesImage}
        heading="About Us"
        description="Learn more about our expertise and commitment to electrical power system excellence."
      />
      <CompanyOverview />
      <WhoWeAreSection />
      <VisionMissionSection />
      <ExperienceSection />
      <FooterSection />
    </div>
  );
};

export default About;
