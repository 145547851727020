import React from "react";
import NavBar from "../components/NavBar";
import FooterSection from "../components/FooterSection";
import HeroSection from "../components/HeroSection";
import ContactForm from "../components/ContactForm";
import ourServicesImage from "../assets/images/our-services.jpg";

const ContactInfoCard = ({ icon, title, info, additionalInfo }) => (
  <div className="bg-white p-8 rounded-lg shadow-lg mb-6 hover:shadow-xl transition-all duration-300 border border-gray-100">
    <div className="text-custom-blue mb-6 flex items-center">
      <div className="bg-blue-50 p-3 rounded-full">{icon}</div>
      <h3 className="text-xl font-semibold text-gray-900 ml-4">{title}</h3>
    </div>
    {title === "Email Us" ? (
      <a
        href="mailto:ashaik@threephasepowers.com"
        className="text-gray-600 hover:text-custom-red transition-colors duration-300 block mb-3"
      >
        {info}
      </a>
    ) : title === "Call Us" ? (
      <a
        href="tel:+17134437247"
        className="text-gray-600 hover:text-custom-red transition-colors duration-300 block mb-3"
      >
        {info}
      </a>
    ) : title === "Visit Us" ? (
      <a
        href="https://www.google.com/maps/place/8990+Kirby+Dr,+Houston,+TX+77054,+USA"
        target="_blank"
        rel="noopener noreferrer"
        className="text-gray-600 hover:text-custom-red transition-colors duration-300 block mb-3"
      >
        {info}
      </a>
    ) : (
      <p className="text-gray-600 mb-3">{info}</p>
    )}
    <p className="text-sm text-gray-500">{additionalInfo}</p>
  </div>
);

const Contact = () => {
  const contactInfo = [
    {
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6"
        >
          <path
            d="M12 21C15.5 17.4 19 14.1764 19 10.2C19 6.22355 15.866 3 12 3C8.13401 3 5 6.22355 5 10.2C5 14.1764 8.5 17.4 12 21Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      title: "Visit Us",
      info: "8990 Kirby Dr, Unit 220, South Main, Houston, United States, 77054",
      additionalInfo: "Monday - Friday: 9:00 AM - 6:00 PM",
    },
    {
      icon: (
        <svg
          height="24px"
          width="24px"
          version="1.1"
          id="_x32_"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          className="w-6 h-6"
        >
          <path
            fill="currentColor"
            d="M94.811,21.696c-35.18,22.816-42.091,94.135-28.809,152.262c10.344,45.266,32.336,105.987,69.42,163.165 c34.886,53.79,83.557,102.022,120.669,129.928c47.657,35.832,115.594,58.608,150.774,35.792 c17.789-11.537,44.218-43.058,45.424-48.714c0,0-15.498-23.896-18.899-29.14l-51.972-80.135 c-3.862-5.955-28.082-0.512-40.386,6.457c-16.597,9.404-31.882,34.636-31.882,34.636c-11.38,6.575-20.912,0.024-40.828-9.142c-24.477-11.262-51.997-46.254-73.9-77.947c-20.005-32.923-40.732-72.322-41.032-99.264c-0.247-21.922-2.341-33.296,8.304-41.006 c0,0,29.272-3.666,44.627-14.984c11.381-8.392,26.228-28.286,22.366-34.242l-51.972-80.134c-3.401-5.244-18.899-29.14-18.899-29.14C152.159-1.117,112.6,10.159,94.811,21.696z"
          />
        </svg>
      ),
      title: "Call Us",
      info: "+1 713-443-7247",
      additionalInfo: "Available 24/7 for emergencies",
    },
    {
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.75 5.25L3 6V18L3.75 18.75H20.25L21 18V6L20.25 5.25H3.75ZM4.5 7.6955V17.25H19.5V7.69525L11.9999 14.5136L4.5 7.6955ZM18.3099 6.75H5.68986L11.9999 12.4864L18.3099 6.75Z"
            fill="currentColor"
          />
        </svg>
      ),
      title: "Email Us",
      info: "ashaik@threephasepowers.com",
      additionalInfo: "We'll respond within 24 hours",
    },
  ];

  return (
    <div className="min-h-screen flex flex-col">
      <NavBar />

      <HeroSection
        backgroundImage={ourServicesImage}
        heading="Contact Us"
        description="Get in touch with us for any questions about our services."
      />

      <div className="py-16 bg-gray-50">
        <div className="container mx-auto px-4 max-w-6xl">
          <div className="flex flex-col md:flex-row gap-10">
            {/* Left Side - Contact Info */}
            <div className="w-full md:w-1/2 space-y-6">
              {contactInfo.map((item, index) => (
                <ContactInfoCard key={index} {...item} />
              ))}
            </div>

            {/* Right Side - Contact Form */}
            <div className="w-full md:w-1/2">
              <div className="bg-white rounded-lg shadow-lg p-8 border border-gray-100">
                <h2 className="text-2xl font-bold text-gray-900 mb-8 text-center">
                  Contact Form
                </h2>
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Google Maps */}
      <div className="relative h-[400px] w-full">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3467.6791958523394!2d-95.41340542426815!3d29.6856777815274!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640ea85d5f0f5d1%3A0x9c93b0caea2a5bd!2s8990%20Kirby%20Dr%2C%20Houston%2C%20TX%2077054%2C%20USA!5e0!3m2!1sen!2sin!4v1708346548569!5m2!1sen!2sin"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className="cursor-pointer"
          onClick={() =>
            window.open(
              "https://www.google.com/maps/place/8990+Kirby+Dr,+Houston,+TX+77054,+USA",
              "_blank"
            )
          }
        ></iframe>

        {/* View Larger Map button */}
        <div className="absolute bottom-4 right-4">
          <a
            href="https://www.google.com/maps/place/8990+Kirby+Dr,+Houston,+TX+77054,+USA"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-white px-4 py-2 rounded-lg shadow-lg text-gray-700 hover:bg-gray-100 transition-colors duration-300"
          >
            View Larger Map
          </a>
        </div>
      </div>

      <FooterSection />
    </div>
  );
};

export default Contact;
