import React from 'react';
import { motion } from 'framer-motion';

const HeroSection = ({ backgroundImage, heading, description }) => {
  return (
    <div 
      className="relative py-24 md:py-32 bg-cover bg-center flex items-center" // Added more padding and flex
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      {/* Dark Overlay */}
      <div className="absolute inset-0 bg-black opacity-60"></div>

      {/* Content */}
      <div className="container mx-auto px-4 relative z-10">
        <div className="max-w-4xl mx-auto"> {/* Added max-width container */}
          <motion.h1 
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-6 leading-tight tracking-tight" // Enhanced typography
          >
            {heading}
          </motion.h1>
          <motion.p 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="text-lg md:text-xl text-gray-100 leading-relaxed max-w-3xl" // Enhanced readability
          >
            {description}
          </motion.p>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;