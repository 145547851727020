// import React from "react";
// import { motion } from "framer-motion";
// import NavBar from "../components/NavBar";
// import FooterSection from "../components/FooterSection";
// import data from "../data.json";
// import ourServicesImage from "../assets/images/our-services.jpg";
// import HeroSection from "../components/HeroSection";

// const ServiceContent = ({ service, isEven }) => {
//   const containerVariants = {
//     hidden: { opacity: 0, y: 50 },
//     visible: {
//       opacity: 1,
//       y: 0,
//       transition: {
//         duration: 0.5,
//         ease: "easeOut",
//       },
//     },
//   };

//   return (
//     <motion.div
//       variants={containerVariants}
//       initial="hidden"
//       whileInView="visible"
//       viewport={{ once: true, amount: 0.2 }}
//       className="flex flex-col lg:flex-row w-full py-16 px-4 lg:px-8 gap-8"
//     >
//       {/* Service Image */}
//       <div className={`w-full lg:w-1/2 ${isEven ? "lg:order-2" : ""}`}>
//         <div className="relative h-64 lg:h-96 overflow-hidden rounded-lg shadow-lg">
//           <img
//             src={require(`../assets/images/${service.image}`)}
//             alt={service.heading}
//             className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-500"
//           />
//         </div>
//       </div>

//       {/* Service Description */}
//       <div
//         className={`w-full lg:w-1/2 flex flex-col justify-center ${
//           isEven ? "lg:order-1" : ""
//         }`}
//       >
//         <h2 className="text-3xl font-bold text-gray-800 mb-6">
//           {service.heading}
//         </h2>
//         <p className="text-lg text-gray-600 mb-6">
//           {service.longDescription || service.shortDescription}
//         </p>
//         <ul className="space-y-3">
//           {service.bulletPoints.map((point, index) => (
//             <li key={index} className="flex items-start">
//               <span className="text-custom-blue mr-2">✓</span>
//               <span className="text-gray-700">{point}</span>
//             </li>
//           ))}
//         </ul>
//       </div>
//     </motion.div>
//   );
// };

// const Services = () => {
//   const { servicesList } = data;

//   return (
//     <div className="min-h-screen flex flex-col">
//       <NavBar />

//       {/* Hero Section */}
//       <HeroSection
//         backgroundImage={ourServicesImage}
//         heading="Our Services"
//         description="Discover our comprehensive range of electrical power system solutions designed 
//           to optimize efficiency, reliability, and safety for your infrastructure."
//       />

//       {/* Services Content */}
//       <div className="flex-grow">
//         <div className="container mx-auto">
//           {servicesList.map((service, index) => (
//             <React.Fragment key={service.id}>
//               <ServiceContent service={service} isEven={index % 2 === 0} />
//               {index < servicesList.length - 1 && (
//                 <div className="border-b border-gray-200 mx-8" />
//               )}
//             </React.Fragment>
//           ))}
//         </div>
//       </div>

//       <FooterSection />
//     </div>
//   );
// };

// export default Services;



import React from "react";
import { motion } from "framer-motion";
import NavBar from "../components/NavBar";
import FooterSection from "../components/FooterSection";
import data from "../data.json";
import ourServicesImage from "../assets/images/our-services.jpg";
import HeroSection from "../components/HeroSection";

const ServiceContent = ({ service, isEven }) => {
  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.2 }}
      className="flex flex-col lg:flex-row w-full py-12 gap-8" // Reduced padding
    >
      {/* Service Image */}
      <div className={`w-full lg:w-1/2 ${isEven ? "lg:order-2" : ""}`}>
        <div className="relative h-48 lg:h-72 overflow-hidden rounded-lg shadow-lg"> {/* Reduced height */}
          <img
            src={require(`../assets/images/${service.image}`)}
            alt={service.heading}
            className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-500"
          />
        </div>
      </div>

      {/* Service Description */}
      <div
        className={`w-full lg:w-1/2 flex flex-col justify-center ${
          isEven ? "lg:order-1" : ""
        }`}
      >
        <h2 className="text-2xl lg:text-3xl font-bold text-gray-800 mb-4"> {/* Adjusted size and margin */}
          {service.heading}
        </h2>
        <p className="text-base lg:text-lg text-gray-600 mb-4"> {/* Adjusted size and margin */}
          {service.longDescription || service.shortDescription}
        </p>
        <ul className="space-y-2"> {/* Reduced spacing */}
          {service.bulletPoints.map((point, index) => (
            <li key={index} className="flex items-start">
              <span className="text-custom-blue mr-2 mt-1">✓</span>
              <span className="text-gray-700">{point}</span>
            </li>
          ))}
        </ul>
      </div>
    </motion.div>
  );
};

const Services = () => {
  const { servicesList } = data;

  return (
    <div className="min-h-screen flex flex-col">
      <NavBar />

      <HeroSection
        backgroundImage={ourServicesImage}
        heading="Our Services"
        description="Discover our comprehensive range of electrical power system solutions designed 
          to optimize efficiency, reliability, and safety for your infrastructure."
      />

      {/* Services Content */}
      <div className="py-12 bg-gray-50"> {/* Added background and padding */}
        <div className="container mx-auto px-4 max-w-6xl"> {/* Added max width and padding */}
          {servicesList.map((service, index) => (
            <React.Fragment key={service.id}>
              <ServiceContent service={service} isEven={index % 2 === 0} />
              {index < servicesList.length - 1 && (
                <div className="border-b border-gray-200 my-8" /> 
              )}
            </React.Fragment>
          ))}
        </div>
      </div>

      <FooterSection />
    </div>
  );
};

export default Services;